import React from "react";
import PropTypes from "prop-types";
import { MapDiv, MapContainer } from "./elements";
import MapSVG from "./MapSVG";

const Map = ({ showCommunityMember, goToLocation, handleSetCurrentType }) => {
  return (
    <MapContainer>
      <MapDiv>
        <MapSVG
          handleSetCurrentType={handleSetCurrentType}
          focusedLocation={goToLocation}
          showCommunityMember={showCommunityMember}
        />
        <div id="tooltip" className="tooltip"></div>
      </MapDiv>
    </MapContainer>
  );
};

export default Map;

Map.propTypes = {
  /**
   * This is the list of map entries (coming from CraftCMS/the map holder component)
   */
  mapLocations: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, title: PropTypes.string })
  ),
  /**
   * This should be the ID of a location that the map should focus on.
   */
  goToLocation: PropTypes.string,
  /**
   * This passes back the ID of the selected location
   */
  showCommunityMember: PropTypes.func,
};
