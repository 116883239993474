import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import ScheduleHero from "../components/ScheduleHero";
import ScheduleMapToggle from "../components/ScheduleMapToggle";
import { EventDiv, ScheduleDiv } from "../components/ScheduleStyles/elements";
import Map from "../components/Map";
import { Location } from "@reach/router";
import { graphql, navigate, useStaticQuery } from "gatsby";
import NotFoundEvent from "../components/NotFoundEvent";
import EventList from "../components/EventList";
import { Container } from "../components/HomepageIntro/elements";
import DownloadV2 from "../components/icons/DownloadV2";
import { DownloadButton } from "../components/DownloadButton/elements";
import EventListGrid from "../components/EventListGrid";
import ParticipantList from "../components/ParticipantList";
import { AnimatedButton } from "../components/Buttons/elements";

const ScheduleMapPage = () => {
  const scheduleQuery = useStaticQuery(graphql`
    {
      pageConfig: craftQafPageVenueQafPageVenueEntry {
        titleSeo
        descriptionSeo
        venueTitle
        allowDownloadProgramme
        downloadButtonText
        showRelativeEvent
        showRelativeParticipants
        pdfProgrammeFile {
          id
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              publicURL
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              publicURL
            }
          }
        }
      }
      events: allCraftQafEventsQafEventEntry {
        nodes {
          id
          title
          slug
          subtitle
          eventShortDescription
          headerImage: qafEventHeaderImage {
            ... on Craft_eventsAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 4000, fit: COVER, quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          qafEventType {
            ... on Craft_qafEventType_default_Entry {
              id
              title
            }
          }
          eventStartDateTime
          eventEndDateTime
          qafEventLocation {
            title
            ... on Craft_maplocation_default_Entry {
              locationId
              allowClick
              title
              locationName
            }
          }
        }
      }
      participants: allCraftParticipantsDefaultEntry(filter: { isDraft: { eq: false } }) {
        nodes {
          id
          communityMemberSlug
          qafParticipantType {
            ... on Craft_qafParticipantType_default_Entry {
              id
              title
            }
          }
          communityMemberWebsite
          communityMemberEmailAddress
          subtitle
          communityMemberOpeningHours
          participantLocation {
            title
          }
          communityMemberDescription
          communityMemberContactDetails {
            method
            value
          }
          slug
          title
          headerImage: qafParticipantHeaderImage {
            ... on Craft_communityAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 1024, quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            ... on Craft_eventsAssets_Asset {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 1024, quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const now = new Date();
  const events = scheduleQuery?.events?.nodes?.filter((item) => {
    const thisDate = new Date(item.eventEndDateTime || item.eventStartDateTime || null);
    return thisDate >= now;
  });
  const participants = scheduleQuery?.participants?.nodes;

  const pageConfig = scheduleQuery?.pageConfig;
  const {
    titleSeo,
    descriptionSeo,
    venueTitle,
    allowDownloadProgramme,
    pdfProgrammeFile,
    downloadButtonText,
    showRelativeEvent,
    showRelativeParticipants,
  } = pageConfig;

  const [currentType, setCurrentType] = useState("");
  const [eventList, setEventList] = useState([]);
  const [participantList, setParticipantList] = useState([]);
  const [theme, setTheme] = useState("");

  useEffect(() => {
    const _eventList = events.filter((item) => {
      let flag = false;
      item?.qafEventLocation.forEach((location) => {
        if (location?.title === currentType) {
          flag = true;
        }
      });
      return flag;
    });
    const _participantList = participants?.filter((item) => {
      return item?.participantLocation?.[0]?.title === currentType;
    });
    setEventList(_eventList);
    setParticipantList(_participantList);
  }, [currentType]);

  const formatTitle = (title) => {
    let _title = "";
    title.split("_").forEach((item) => {
      _title += item + " ";
    });
    return _title;
  };

  return (
    <Location>
      {({ location }) => (
        <Layout
          backgroundColor={"var(--bg-color)"}
          skipHeader
          headerBlack={true}
          title={titleSeo}
          SEODescription={descriptionSeo}
          //   footerColor={"var(--red)"}
          //   title="Alserkal Avenue | A Community for
          // Contemporary Art & Homegrown Concepts"
          //   SEODescription="If you want dynamic cultural experiences, from
          // contemporary art and photography to spinning and yoga
          // classes, discover Alserkal Avenue today."
        >
          <ScheduleDiv style={{ height: "auto", marginTop: "110px" }}>
            <ScheduleHero topLine={venueTitle} />
            {allowDownloadProgramme && pdfProgrammeFile.length && pdfProgrammeFile[0].localFile ? (
              <Container style={{ padding: 0 }}>
                <a href={pdfProgrammeFile[0].localFile?.publicURL} download="Programme" target="_blank">
                  <AnimatedButton>
                    <DownloadButton>
                      <DownloadV2 color="#101820" />
                      {downloadButtonText ?? "Programme"}
                    </DownloadButton>
                  </AnimatedButton>
                </a>
              </Container>
            ) : null}
            {/* <ScheduleMapToggle isMap /> */}
            <Map
              useFakeMap={false}
              handleSetCurrentType={(type) => {
                setCurrentType(type);
                document.getElementById("map-events").scrollIntoView({ behavior: "smooth" });
              }}
              goToLocation={location.search.indexOf("?location=") > -1 ? decodeURI(location.search.split("?location=")[1]) : ""}
              showCommunityMember={(e) => {
                navigate(`/community/${e}`);
              }}
            />
            <EventDiv id="map-events">
              {currentType && (
                <>
                  <p>
                    <span>{formatTitle(currentType)}</span>
                  </p>
                  {eventList?.length && showRelativeEvent ? (
                    <EventListGrid
                      titleColor="var(--primary)"
                      smallTitle
                      hideArrow
                      padding="0"
                      margin="0"
                      title={"what's happening"}
                      events={eventList}
                      itemsPerLine={3}
                    />
                  ) : null}
                  {participantList?.length && showRelativeParticipants ? (
                    <ParticipantList
                      titleColor="var(--primary)"
                      smallTitle
                      hideArrow
                      padding="0"
                      margin="0"
                      title={"who's here"}
                      participants={participantList}
                    />
                  ) : null}
                  {!eventList?.length && !participantList?.length ? <NotFoundEvent /> : null}
                </>
              )}
            </EventDiv>
          </ScheduleDiv>
        </Layout>
      )}
    </Location>
  );
};

export default ScheduleMapPage;
