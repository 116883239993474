import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { NotFoundDiv } from "./elements";
import { H2SmallStrong, H3SmallMedium } from "../Typography";

const NotFoundEvent = ({}) => {

  return (
    <NotFoundDiv>
      <div>
        <H2SmallStrong>Intriguing things are happening here!</H2SmallStrong>
        <H3SmallMedium>Pop into the venue to find out more...</H3SmallMedium>
      </div>
    </NotFoundDiv>
  );
};

export default NotFoundEvent;
