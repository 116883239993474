import styled from "styled-components";

export const Container = styled.div`
  margin: ${(prop) => (prop.margin ? prop.margin : "0px 100px")};
  padding: ${(prop) => (prop.padding ? prop.padding : "50px 0")};
  color: ${(prop) => (prop.titleColor ? prop.titleColor : "var(--text-color)")};

  @media (max-width: 1000px) {
    width: unset;
    padding: ${(prop) => (prop.padding ? prop.padding : "36px 20px")};
    margin: 0;
    > h3 {
      font-size: 24px;
    }
  }
  > h3,
  > h4 {
    margin-bottom: 20px;
    svg {
      width: 20px;
      height: 10px;
      margin-left: 25px;
    }
  }
`;

export const CommunityCard = styled.div`
  width: 295px;
  height: 305px;
  margin-bottom: 12px;
  border-top: 10px solid transparent;
  border-top-color: var(--primary);
  position: relative;
  cursor: ${(props) => (props.clickable ? "var(--cursor-hover) 0 0, pointer" : "unset")};
  transition: all 0.3s;
  &:hover {
    transform: scale(1.03);
    transition: all 0.3s;
  }
  & h6 {
    position: absolute;
    bottom: 47px;
    left: 20px;
    color: var(--primary);
    text-shadow: 0px 0.4px 0px var(--primary);
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & p {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: var(--text-color);
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > a {
    color: var(--black);
    & > div {
      /* background-color: var(--red); */
      width: 100%;
      /* min-height: 400px; */
      & > div {
        height: 295px !important;
        width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    height: 305px !important;
    width: 100% !important;
    & h5 {
      text-align: center;
    }
  }
  @media (max-width: 576px) {
    box-sizing: content-box;
    height: 157.5px !important;
    border-top-width: 6px !important;
    & > a > div {
      & > div:first-child {
        height: 157.5px !important;
      }
    }
    & > a {
      & > h6 {
        font-weight: 700;
        font-size: 15px;
        line-height: 140%;
        bottom: 32px;
        left: 12px;
      }
      & > p {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        bottom: 12px;
        left: 12px;
      }
    }
  }
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  inset: 0 0 0 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 62.2%, rgba(0, 0, 0, 0.65) 86.1%);
`;

export const ListCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 767px) {
    > div {
      width: calc(50% - 10px) !important;
    }
  }
  @media (max-width: 300px) {
    > div {
      width: 100% !important;
    }
  }
`;
