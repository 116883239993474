import React from "react";

const DownloadV2 = ({ color }) => (
  <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="download">
      <mask id="mask0_12582_1238" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="21">
        <rect id="Bounding box" y="0.5" width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_12582_1238)">
        <path
          id="download_2"
          d="M10.0026 13.8333L5.83594 9.66665L7.0026 8.45831L9.16927 10.625V3.83331H10.8359V10.625L13.0026 8.45831L14.1693 9.66665L10.0026 13.8333ZM5.0026 17.1666C4.54427 17.1666 4.15191 17.0035 3.82552 16.6771C3.49913 16.3507 3.33594 15.9583 3.33594 15.5V13H5.0026V15.5H15.0026V13H16.6693V15.5C16.6693 15.9583 16.5061 16.3507 16.1797 16.6771C15.8533 17.0035 15.4609 17.1666 15.0026 17.1666H5.0026Z"
          fill={color || "#fff"}
        />
      </g>
    </g>
  </svg>
);

export default DownloadV2;
