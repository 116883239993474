import { graphql, useStaticQuery } from "gatsby";

export const handleLocation = (qafEventLocation) => {
  const data = useStaticQuery(graphql`
    {
      craftGlobalSetInterface(handle: { eq: "siteQAFSettings" }) {
        ... on Craft_siteQAFSettings_GlobalSet {
          venuesLocation
        }
      }
    }
  `);

  const venuesLocation = data?.craftGlobalSetInterface?.venuesLocation;

  if (venuesLocation) {
    return qafEventLocation?.length
      ? [
          ...qafEventLocation
            .filter((item) => item?.locationId && item?.allowClick)
            .map((item) => item?.title),
          venuesLocation,
        ].join(", ")
      : "";
  } else {
    return qafEventLocation?.length
      ? [
          ...qafEventLocation
            .filter((item) => item?.locationId && item?.allowClick)
            .map((item) => item?.title),
        ].join(", ")
      : "";
  }
};
