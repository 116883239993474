import styled from "styled-components";

export const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: var(--headerFont);
  color: var(--btn-text);
  background: var(--btn-bg);
  border: none;
  padding: 8px 8px;
  min-width: 190px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.06em;
  line-height: 1.4;
  transition: all 0.3s;
  > svg path{
    fill: var(--btn-text);
  }
  &.transparent {
    color: var(--white);
    background: transparent;
    border: 2px solid var(--primary);
  }
  &:hover {
    cursor: var(--cursor-hover) 0 0, pointer;
    transform: scale(1.05);
    transition: all 0.3s;
  }
  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;
