import React from "react";
import { Container } from "./elements";
import ArrowDown from "../icons/ArrowDown";
import { H3, H4Strong } from "../Typography";
import EventCardList from "../EventCardList";

const EventListGrid = ({ title, events, padding, margin, titleColor, hideArrow, smallTitle, itemsPerLine }) => {
  const lgSize = 12;
  const mdSize = 8;

  itemsPerLine = itemsPerLine ?? 3;
  const itemsPerLineMd = Math.ceil(itemsPerLine / 2);
  const lg_layout = Array.from({ length: itemsPerLine }, (_, i) => {
    return {
      x: i * (lgSize / itemsPerLine),
      y: 0,
      w: lgSize / itemsPerLine,
      h: 1,
    };
  });

  const md_layout = Array.from({ length: itemsPerLineMd }, (_, i) => {
    return {
      x: i * (mdSize / itemsPerLineMd),
      y: 0,
      w: mdSize / itemsPerLineMd,
      h: 1,
    };
  });

  return (
    <Container margin={margin} padding={padding} titleColor={titleColor}>
      {smallTitle ? (
        <H4Strong>
          {title}
          {!hideArrow ? <ArrowDown color={"var(--text-color)"} /> : null}
        </H4Strong>
      ) : (
        <H3>
          {title}
          {!hideArrow ? <ArrowDown color={"var(--text-color)"} /> : null}
        </H3>
      )}
      <EventCardList events={events} lg_layout={lg_layout} md_layout={md_layout} rowHeight={295} spacing={20} />
    </Container>
  );
};

export default EventListGrid;
