import styled from "styled-components";

export const EventGridHolder = styled.div`
  --rowGap: 20px;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 50px;
  padding-left: ${(props) => (props.usePadding ? "var(--outerMargin)" : 0)};
  padding-right: ${(props) => (props.usePadding ? "var(--outerMargin)" : 0)};
  --accentColor: ${(props) =>
    props.accentColor ? props.accentColor : "var(--red)"};
  --bottomRule: ${(props) =>
    props.useBottomRule ? "1.5px solid var(--accentColor)" : "none"};
  --bottomPadding: ${(props) => (props.useBottomRule ? "20px" : 0)};
  @media (max-width: 767px) {
    --rowGap: 20px;
    justify-content: center;
  }
`;

export const EventGridDiv = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: ${(props) =>
    props.numberColumns === 2 ? "400px 400px" : "400px 400px 400px"};
  grid-column-gap: 20px;
  grid-row-gap: var(--rowGap);
  grid-auto-flow: dense;
  @media (max-width: 1439px) {
    grid-template-columns: 400px 400px;
  }
  @media (max-width: ${(props) => (props.useFullWidth ? "880px" : "1023px")}) {
    grid-template-columns: 400px;
    justify-content: center;
  }
  @media (max-width: 767px) {
    grid-template-columns: 335px;
    margin-bottom: 0;
    & > div {
      padding: 12px;
      height: 201px;
      & > a > div {
        &.gatsby-image-wrapper,
        img {
          height: 100% !important;
          @media only screen and (max-width: 767px) {
            height: 177px !important;
            width: 148px !important;
          }
        }
      }
    }
  }
`;

export const DateCardDiv = styled.div`
  display: flex;
  border-top: 1.5px solid var(--accentColor);
  padding-top: 20px;
  width: ${(props) =>
    props.size === 1 || props.size === 2
      ? "var(--cardWidthWide)"
      : "var(--cardWidthNormal)"};
  color: var(--accentColor);
  border-bottom: var(--bottomRule);
  padding-bottom: var(--bottomPadding);
  @media (max-width: 767px) {
    width: 375px;
  }
`;

export const EventCardDiv = styled.div`
  --color: ${(props) => props.color || "var(--red)"};
  --cardWidthExtraWide: 820px;
  --cardWidthWide: 820px;
  --cardWidthNormal: 400px;
  --extraWideWidth: 610px;
  --extraWideHeight: 450px;
  --wideWidth: 400px;
  --wideHeight: 300px;
  --normalWidth: 172px;
  --cardHeight: 237.5px;
  --normalHeight: 205.5px;
  --titleTextColor: ${(props) => props.titleTextColor || "var(--black)"};
  --infoTextColor: ${(props) => props.infoTextColor || "var(--slate)"};
  --typeTextColor: ${(props) => props.typeTextColor || "var(--dark)"};
  display: flex;
  background-color: var(--color);
  padding: 16px;
  max-height: var(--cardHeight);
  @media (max-width: 767px) {
    --normalWidth: 148px;
    --cardHeight: 201px;
    > div {
      max-width: 151px;
      width: 100%;
    }
  }
  width: ${(props) =>
    props.size === 2
      ? "var(--cardWidthExtraWide)"
      : props.size === 1
      ? "var(--cardWidthWide)"
      : "var(--cardWidthNormal)"};
  grid-column-end: ${(props) =>
    props.size === 2 || props.size === 1 ? "span 2" : "span 1"};
  border-bottom: var(--bottomRule);
  transition: all 0.3s;
  &:hover {
    transform: scale(1.03);
    transition: all 0.3s;
  }
  & > a > .gatsby-image-wrapper,
  img {
    object-fit: cover;
    margin-right: 20px;
    height: ${(props) =>
      props.size === 2
        ? "var(--extraWideHeight)"
        : props.size === 1
        ? "var(--wideHeight)"
        : "var(--normalHeight)"} !important;
    min-width: ${(props) =>
      props.size === 2
        ? "var(--extraWideWidth)"
        : props.size === 1
        ? "var(--wideWidth)"
        : "var(--normalWidth)"};
    max-width: ${(props) =>
      props.size === 2
        ? "var(--extraWideWidth)"
        : props.size === 1
        ? "var(--wideWidth)"
        : "var(--normalWidth)"};
  }
  & > a > div {
    margin-right: 20px;
  }
  & > a > .placeholder {
    width: 190px;
    height: 190px;
    background-color: var(--color);
  }
  & h6 a {
    color: var(--textColor);
  }
  & > div {
    max-height: 100%;
    & > h3 > a {
      color: var(--titleTextColor);
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
    }
    & > h6 {
      margin-top: 12px;
      color: var(--titleTextColor);
      & strong {
        display: inline-block;
        width: 40px;
      }
    }
    & > h6 + h6 {
      margin-top: 0;
      color: var(--infoTextColor);
    }
    & > h6:first-of-type {
      color: var(--typeTextColor);
      font-weight: 600;
      margin-top: 0px;
      margin-bottom: 12px;
    }
    & > h3:first-of-type {
      margin-bottom: 12px;
    }
    & > h6:last-of-type {
      color: var(--infoTextColor);
      font-weight: 600;
      margin-top: 8px;
      text-overflow: ellipsis;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    & > h5 {
      margin-top: 12px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
      & a {
        color: var(--textColor);
      }
    }
    & > div {
      font-size: 12px;
      line-height: 18px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 20px;
    margin-bottom: 0;
    & > div {
      padding: 0;
      & h3 {
        font-size: 16px;
      }
      & h6 {
        text-align: left;
      }
      & h5 {
        text-align: center;
      }
    }
  }
  @media (max-width: 1439px) {
    --cardWidthWide: 400px;
    --wideWidth: 190px;
    --wideHeight: 190px;
    /* & .gatsby-image-wrapper {

		} */
  }
  @media (max-width: 1023px) {
    --cardWidthExtraWide: 400px;
    --extraWideWidth: 190px;
    --extraWideHeight: 190px;
    grid-column-end: span 1;
  }
  @media (max-width: 767px) {
    & > div > div {
      text-align: center;
    }
  }
`;

export const EventListTitleDiv = styled.div`
  font-size: ${(props) => props.titleSize || "42px"};
  font-weight: ${(props) => props.titleWeight || "700"};
  margin: 0 0 12px 0;
  color: var(--white);
`;
