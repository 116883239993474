import React from "react";
import { BackgroundOverlay, CommunityCard, Container, ListCard } from "./elements";
import ArrowDown from "../icons/ArrowDown";
import { H3, H4Strong, H6Big, PH7 } from "../Typography";
import Img from "gatsby-image";
import { navigate } from "gatsby";

const ParticipantList = ({ title, participants, padding, margin, titleColor, hideArrow, smallTitle }) => {
  return (
    <Container margin={margin} padding={padding} titleColor={titleColor}>
      {smallTitle ? (
        <H4Strong st>
          {title}
          {!hideArrow ? <ArrowDown /> : null}
        </H4Strong>
      ) : (
        <H3>
          {title}
          {!hideArrow ? <ArrowDown /> : null}
        </H3>
      )}
      {participants?.length ? (
        <ListCard>
          {participants?.map((card, cardIndex) => (
            <CommunityCard
              key={cardIndex}
              clickable={true}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/participant/${card?.slug}`);
              }}
            >
              <Img fixed={card.headerImage[0].localFile.childImageSharp.fixed} style={{ width: "100%", height: "100%" }} alt={`Quoz arts fest participant - ${card?.title} `} />
              <H6Big style={{ zIndex: 1 }}>{card?.title}</H6Big>
              {card?.qafParticipantType && card?.qafParticipantType?.length ? (
                <PH7 style={{ zIndex: 1 }}>{card?.qafParticipantType?.[0]?.title}</PH7>
              ) : null}
              <BackgroundOverlay />
            </CommunityCard>
          ))}
        </ListCard>
      ) : null}
    </Container>
  );
};

export default ParticipantList;
