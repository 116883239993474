import styled from "styled-components";

export const ScheduleHeroDiv = styled.div`
  --color: ${(props) => props.color || "var(--red)"};
  width: 100%;
  padding: 0 var(--outerMargin) 25px;
  & h2 {
    text-align: center;
    color: var(--text-color);
  }
  & h3 {
    color: var(--color);
    text-align: center;
    max-width: calc(calc(var(--width) - var(--outerMargin)) - var(--outerMargin));
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 767px) {
    padding: 0px 20px;
    & h5 {
      display: block !important;
    }
    & h3 {
      text-align: left;
      font-size: 24px;
      letter-spacing: 0.24px;
    }
  }
  @media (max-width: 767px) {
    --outerMargin: 25px;
  }
`;
