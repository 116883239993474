import styled from "styled-components";

export const NotFoundDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--text-color);
  // padding: 50px 0;
  width: 100%;
  > div {
    width: 100%;
    border: 1px solid #fff;
    > h2 {
      padding: 40px 30px 20px;
    }
    > h3 {
      padding: 0 30px 40px;
    }
  }
  @media (max-width: 767px) {
    > div {
      > h2 {
        text-align: center;
        font-size: 18px !important;
        font-weight: 500;
        padding: 20px 15px 15px;
      }
      > h3 {
        text-align: center;
        font-size: 12px !important;
        padding: 0 15px 20px;
      }
    }
  }
`;
