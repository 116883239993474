import styled from "styled-components";

export const Container = styled.div`
  margin: ${(prop) => (prop.margin ? prop.margin : "0px 100px")};
  padding: ${(prop) => (prop.padding ? prop.padding : "50px 0")};
  color: ${(prop) => (prop.titleColor ? prop.titleColor : "var(--text-color)")};

  @media (max-width: 1000px) {
    width: unset;
    padding: ${(prop) => (prop.padding ? prop.padding : "36px 20px")};
    margin: 0;
    > h3 {
      font-size: 24px;
    }
  }
  > h3,
  > h4 {
    margin-bottom: 20px;
    svg {
      width: 20px;
      height: 10px;
      margin-left: 25px;
    }
  }
`;
